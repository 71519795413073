import { createTheme } from '@mui/material/styles';


const darkTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
        text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },

  typography: {
    fontFamily: [
      'Open Sans',
      'Roboto',
      'Arial',
      'Helvetica',
      'Sans-Serif'
    ].join(','),
    fontSize: 24, // Default font size in pixels
    allVariants: {
        fontWeight: 400, // Sets font weight to 700 for all variants
      },
},

  components:{
    MuiTextField: {
        styleOverrides: {
          root: {

            'background':{
                color: 'rgba(32, 32, 36)'
            },

            '& label': {
              color: 'rgba(255, 255, 255, 0.7)', // Light label color in normal state for visibility
            },
            '& label.Mui-focused': {
              color: '#90caf9', // Brighter color for focused label, ensuring visibility
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: 'rgba(255, 255, 255, 0.42)', // Lighter underline in normal state for visibility
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#90caf9', // Brighter color for underline on focus
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)', // Adjusted for better visibility against dark background
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.87)', // Brighter on hover for visibility
              },
              '&.Mui-focused fieldset': {
                borderColor: '#90caf9', // Brighter primary color for focused border
              },
              '& input': {
                color: '#fff', // Ensuring input text is white for visibility
              },
            },
          },
        },
      },
      
  }
});


const ddarkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#90caf9',
//     },
//     secondary: {
//       main: '#f48fb1',
//     },
//     background: {
//       default: '#121212',
//       paper: '#242424',
//     },
//     text: {
//       primary: '#ffffff',
//       secondary: '#aaaaaa',
//     },
//     typography: {
//         fontFamily: [
//           'Roboto',
//           'Arial',
//           'Helvetica',
//           'Sans-Serif'
//         ].join(','),
//         fontSize: 240, // Default font size in pixels
//     }
//   },
//   components: {
//     // Styling for Box
//     MuiBox: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#121212', // You might want to adjust this
//         },
//       },
//     },
//     // Styling for Typography
//     MuiTypography: {
//       styleOverrides: {
//         root: {
//           color: '#ffffff', // Ensuring text color is visible in dark mode
//         },
//       },
//     },
//     // Styling for TextField
//     MuiTextField: {
//         styleOverrides: {
//           root: {
//             '& label.Mui-focused': {
//               color: '#64b5f6', // A lighter primary color for focused label suitable for dark themes
//             },
//             '& .MuiInput-underline:after': {
//               borderBottomColor: '#64b5f6', // A lighter primary color for underline suitable for dark themes
//             },
//             '& .MuiOutlinedInput-root': {
//               '& fieldset': {
//                 borderColor: 'rgba(255, 255, 255, 0.7)', // Lighter and more visible against dark backgrounds
//               },
//               '&:hover fieldset': {
//                 borderColor: 'rgba(255, 255, 255, 1)', // More pronounced on hover for better visibility
//               },
//               '&.Mui-focused fieldset': {
//                 borderColor: '#64b5f6', // A lighter primary color for focused border suitable for dark themes
//               },
//             },
//           },
//         },
//       },
      
//     // Styling for Avatar
//     MuiAvatar: {
//       styleOverrides: {
//         root: {
//           // No specific dark mode adjustments needed, but you can customize as needed
//         },
//       },
//     },
//     // Styling for Paper
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#242424',
//           color: '#ffffff',
//         },
//       },
//     },
//     // Styling for List and ListItem
//     MuiList: {
//       styleOverrides: {
//         root: {
//           // Adjust as necessary for your use case
//         },
//       },
//     },
//     MuiListItem: {
//       styleOverrides: {
//         root: {
//           // Adjust as necessary for your use case
//         },
//       },
//     },
//     MuiListItemAvatar: {
//       styleOverrides: {
//         root: {
//           // Adjust as necessary for your use case
//         },
//       },
//     },
//     // Styling for Container
//     MuiContainer: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#121212', // You might want to adjust this based on your design
//         },
//       },
//     },
//     // You can also add custom components here if needed
//   },
// });

export default darkTheme;
