import React, { useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';

import Markdown from 'mui-markdown';


function closeMarkdownAsterisks(text) {
  if (text.length === 0) return ""
  let italicCount = 0, boldCount = 0, boldItalicCount = 0;

  for (let i = 0; i < text.length; ++i) {
      if (text[i] === '*') {
          // Check for bold + italic
          if (text[i+1] === '*' && text[i+2] === '*') {
              boldItalicCount++;
              i += 2; // Skip the next two asterisks
          }
          // Check for bold
          else if (text[i+1] === '*') {
              boldCount++;
              i++; // Skip the next asterisk
          }
          // Italic
          else {
              italicCount++;
          }
      }
  }
  let toClose = '';
  if (boldItalicCount % 2 !== 0) toClose += '***';
  if (boldCount % 2 !== 0) toClose += '**';
  if (italicCount % 2 !== 0) toClose += '*';
  return text + toClose;
}



const Text = ({ text, key }) => {
  // Styles to prevent unexpected margins and handle long unbroken text
  const textStyle = {
    whiteSpace: 'pre-wrap', // Preserves whitespace and wraps text
    wordBreak: 'break-word', // Breaks the word at the end of the line
    overflowWrap: 'break-word', // Allows unbreakable words to be broken
    maxWidth: '100%', // Ensures the Typography component does not exceed its parent width
    boxSizing: 'border-box', // Ensures padding is included in the width
    margin: 0, // Resets any default margins
    padding: '8px', // Optional: adjust the padding to match your design
    // Add any other styles you need here
  };

  text = closeMarkdownAsterisks(text)

  return (
    <Typography key={key} style={textStyle} component="div">
      <Markdown>{text}</Markdown>
    </Typography>
  );
};




// Assuming you will fill out the IMAGES and AUDIO objects
const IMAGES = {};
const AUDIO = {};

// Corrected Image component
const Image = ({ text, key }) => {
  // const imageName = IMAGES[text];
  // if (!imageName) return null;
  return <img src="https://firebasestorage.googleapis.com/v0/b/character-games.appspot.com/o/test%20profile.jpg?alt=media&token=75bc0bd0-0849-492f-91e3-5355caba4f07" 
  alt={text} key={key}
  style={{ borderRadius: '20px' }}></img> // This applies rounded corners />;
};

// Corrected Audio component
const Audio = ({ text, stream }) => {
  useEffect(() => {
    if (stream) {
      const audioName = AUDIO[text];
      if (!audioName) return;
      
      const audio = new Audio(audioName);
      audio.play();
    }
  }, [text, stream]);

  return null;
};

const BaseDisplayBox = ({ color, text, key }) => {
  return (
    <Box
      key={key}
      sx={{
        backgroundColor: color,
        padding: '8px',
        borderRadius: '20px',
        margin: '8px',
        marginTop: '0px',
        marginBottom: '0px',
        display: 'inline-block',
      }}
    >
      <Text text = {text}></Text>
    </Box>
  );
};

const NextOptionButton = ({ text, handleSendMessage, messages, index, isLatestMessage }) => {
  // Ensure the button is only rendered for the last message in the list
  if (isLatestMessage) return null;

  // Function to handle button click: sets the input message and then sends it
  const handleClick = () => {
    handleSendMessage(text);  // Pass the button's text directly to the send message handler
  };


  return (
    <Button
      variant="contained"
      onClick={handleClick}  // Attach the handleClick function to button's onClick event
      sx={{ margin: '8px',
      borderRadius: '20px',
    }}
    >
      {text}
    </Button>
  );
};



// Corrected ChangePlayerSanity component
const ChangePlayerSanity = ({ text, key }) => {
  const amount = parseInt(text, 10);
  const color = amount >= 0 ? 'green' : 'rgb(100,50,50,1)';
  const displayText = `**${amount > 0 ? '+' : ''}${amount} ${amount > 0 ?'🧠':'🤯'} Player Sanity**`;
  return <BaseDisplayBox color={color} key={key} text = {displayText}></BaseDisplayBox>;
};

// Corrected ChangeAbigailAffection component
const ChangeAbigailAffection = ({ text, key }) => {
  const amount = parseInt(text, 10);
  const color = amount >= 0 ? 'green' : 'lightred';
  const displayText = `**${amount > 0 ? '+' : ''}${amount} Abigail Affection**`;
  return <BaseDisplayBox color={color} key={key} text = {displayText}></BaseDisplayBox>;
};

// Corrected ChangeRelationshipIntimacy component
const ChangeRelationshipIntimacy = ({ text, key }) => {
  const amount = parseInt(text, 10);
  const color = amount >= 0 ? 'green' : 'red';
  const displayText = `**${amount > 0 ? '+' : ''}${amount} Relationship Intimacy**`;
  return <BaseDisplayBox color={color} key={key} text = {displayText}></BaseDisplayBox>;
};

const TAGS = {
  "text": Text,
  "image": Image,
  "sound": Audio,
  "change player_sanity": ChangePlayerSanity,
  "change abigail_affection": ChangeAbigailAffection,
  "change relationship_intimacy": ChangeRelationshipIntimacy,
  "next option": NextOptionButton,
  "divider":Divider
};



const textToComponents = (text, options) => {
  let buffer = "";
  if (options.isPlayer) return [React.createElement(TAGS["text"], {key: "Player",text: text,...options})];
  const components = [];
  let componentId = 0;

  // Iterate through the text to find and process tags
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char === '<') {
      let closeTagIndex = text.indexOf('>', i);
      if (closeTagIndex !== -1) {
        let tag = text.substring(i + 1, closeTagIndex);
        let isClosingTag = tag.startsWith('/');
        let tagName = isClosingTag ? tag.substring(1) : tag;

        // If we're starting a new tag, process the buffer as plain text
        if (!isClosingTag && buffer) {
          components.push(React.createElement(TAGS["text"], {key: componentId++,text: buffer,...options}));
          buffer = "";
        }

        // Handle the end of a tag
        if (isClosingTag && TAGS[tagName]) {
          components.push(
            React.createElement(TAGS[tagName], {
              key: componentId++,
              text: buffer,
              ...options // Pass all options as props to the component
            })
          );
          buffer = "";
        }

        i = closeTagIndex;
      } else {
        buffer += char; // If we don't find a closing '>', add char to the buffer
      }
    } else {
      buffer += char;
    }
  }

  // If there's any remaining text in the buffer after processing, add it as plain text
  if (buffer) {
    components.push(<Text text = {buffer} props = {options}></Text>);
  }

  return components;
};


export default textToComponents;