import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatPage from './pages/ChatPage/ChatPage';
import darkTheme from './theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/400-italic.css"; // Specify weight and style

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
    <Router>
      <Routes>
        <Route path="/demo" element={<ChatPage />} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}


export default App;