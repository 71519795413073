import {
  Box,
  Typography,
  TextField,
  Avatar,
  Paper,
  List,
  ListItem,
  ListItemText,
  Container,
  ListItemAvatar,
  IconButton,
  Button,
} from '@mui/material';


import SendIcon from '@mui/icons-material/Send';



import 'animate.css';


import React, { useState, useEffect, useRef } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import textToComponents from './convert';



const ChatScrollBox = ({ messages }) => {
    const scrollRef = useRef(null);
    const [showScrollbar, setShowScrollbar] = useState(true);
    const timeoutRef = useRef(null);
  
    const scrollToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
  
    // Scroll to bottom on initial render and on messages change
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    // Effect for auto-hiding the scrollbar
    const handleScroll = () => {
      setShowScrollbar(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setShowScrollbar(false);
      }, 2000);
    };
  
    return (
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '16px',
          '&::-webkit-scrollbar': {
            width: '10px',
            marginRight: '2px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: showScrollbar ? '#888' : 'transparent',
            borderRadius: '4px',
            border: '2px solid transparent',
            transition: 'background 0.5s ease-out', // Add transition property
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            maxWidth: '75%',
            minWidth: '60%',
            mx: 'auto',
          }}
        >
          <List>
            {messages.map((message) => (
              <MessageBubble key={message.id} message={message} />
            ))}
          </List>
        </Box>
      </Box>
    );
};
  



const MessageInputFieldBox = ({ inputMessage, setInputMessage, handleSendMessage }) => {
    
    

    const handleClick = (text) => {
        handleSendMessage(text);  // Pass the button's text directly to the send message handler
    };

    return (
    <Box
      sx={{
        paddingBottom: '48px',
        width: '100%',
        maxWidth: '75%',
        minWidth: '60%',
        mx: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0)',
      }}
    >
    {/* <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"hedsfdsafdfasdfslo"}</Button>
    <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"helo"}</Button>
    <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"helo"}</Button>
    <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"helo"}</Button>
    <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"helo"}</Button>
    <Button variant="contained" onClick={() => handleClick("helo")} sx={{ margin: '16px', padding:'12px',   borderRadius: '20px',}}>{"helo"}</Button> */}
      <Box
        sx={{
          position: 'relative', // For absolute positioning of the send button
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0)'
        }}
      >

        <TextField
          fullWidth
          multiline
          rows={1}
          variant="outlined"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '40px', // Ensuring the rounded corners
              borderWidth: '2px', // Making the outline thicker
              borderColor: 'rgba(0, 0, 0, 0.23)', // Default color, adjust as needed
              backgroundColor: 'rgba(48, 49, 54, 1)', // Background color of the input field
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main', // Color when the field is focused
              },
              '& .MuiInputBase-input': {
                padding: '16px', // Add padding inside the input field for spacing around the text
              },
            },
            //paddingRight: '', // Make space for the button inside the text field
          }}
        />
        <IconButton
          onClick={handleSendMessage}
          color="primary"
          sx={{
            position: 'absolute',
            right: 10, // Adjust based on preference
            backgroundColor: 'white', // Icon's circular background
            borderRadius: '50%', // Makes the background perfectly circular
            '&:hover': {
              backgroundColor: '#eeeeee', // Light grey on hover, adjust as needed
            },
            padding: '20px', // Adjust the size of the circular background
          }}
        >
          <SendIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </IconButton>

      </Box>
    </Box>
  )};
  



const MessageBubble = ({ message }) => (
  <ListItem
    sx={{
      display: 'flex',
      justifyContent: message.sender === 'player' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      marginBottom: '8px',
    }}
  >
    <ListItemAvatar
      sx={{
        order: message.sender === 'player' ? 2 : 1, // Adjust order based on the sender
        marginX: '8px', // Apply horizontal margin
      }}
    >
      <Avatar
        alt={`${message.sender} Avatar`}
        src={message.avatar}
        sx={{ width: 88, height: 88 }}
      />
    </ListItemAvatar>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: message.sender === 'player' ? 'flex-end' : 'flex-start',
        flexGrow: 1,
        order: message.sender === 'player' ? 1 : 2, // Adjust order based on the sender
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'bold',
          marginBottom: '4px',
          color: 'white',
        }}
      >
        {message.sender === 'player' ? 'Player' : message.name}
      </Typography>
      <Paper
        sx={{
          backgroundColor: message.sender === 'player' ? 'rgba(48,49,54,255)' : 'rgba(38,39,43,255)',
          padding: '8px 16px',
          borderRadius: '16px',
          maxWidth: '80%',
          width: 'fit-content',
          gap: '8px',
          flexShrink: 0, // Allow this element to shrink if necessary
        }}
      >
        {message.components.map((element, index) => (
          React.isValidElement(element) ? (
            <React.Fragment key={index}>{element}</React.Fragment>
          ) : (
            <span key={index}>Invalid component</span>
          )
        ))}
      </Paper>
    </Box>
  </ListItem>
);









const ChatPage = () => {
  const [gameState, setGameState] = useState(null)
  const [messages, setMessages] = useState([
    {
      text:`**NOTE: This is a Demo with only this single scenario.**


BACKSTORY:
> You got 💀 from **Truck-kun** 🚚
> You got summoned into a **Fantasy World.** 
> You got a quest to retrieve the **Princess's Neckless** from the **Red Dragon.**`,
      sender: 'character',
      name: 'Game Demo',
      avatar: '/character_avatar.png',
      id:0
    },

    {
      text:`
Alice: Hi I like cats!
<image>cat</image>
<sound>meow</sound>
<text>**Alice**: *I hate dogs*. What ***animal*** do you like?
Bob: I like cats!</text>
<change player_sanity>1</change player_sanity>
<next option>option 1</next option><next option>option 2</next option><next option>option  dsflasjhdfksaljhfddksajhfaskjdfhaskjf3</next option><next option>optiondfsadfsadfsfsdf 4</next option>`,
      sender: 'character',
      name: 'Game Demo',
      avatar: '/character_avatar.png',
      id:1
    },

    {
      text: `Hi there! I need some help with my order. I placed an order a few days ago and haven't received any updates.`,
      sender: 'player',
      name:"Player",
      avatar: '/player_avatar.jpg',
      id:2
    },
    {
      text: `<text>Alice: Hi I like cats!</text>
<image>cat</image>
<sound>meow</sound>

<text>Alice: I hate dogs. What animal do you like?
Bob: I like cats!<text>
> **Bruh** bruh
> *Bruh
<change player_sanity>1</change player_sanity>
<change player_sanity>-10</change player_sanity>
<next option>option 1</next option><next option>option 2</next option><next option>option 3</next option><next option>option 4</next option>`,
      sender: 'character',
      name: 'Game Demo',
      avatar: '/character_avatar.png',
      id:3
    },
  ]);

  const [messageComponents, setMessageComponents] = useState([]);
  
  // Use an useEffect to update messageComponents whenever messages changes
  useEffect(() => {
    const updatedComponents = messages.map((message, index) => ({
      ...message,
      components: textToComponents(message.text, {
        isPlayer: message.sender === 'player',
        isLatestMessage: messages.length-1 !== index,
        setInputMessage,
        handleSendMessage,
        setNextOptionButtons,
        nextOptionButtons,
        setInputState,
        inputState,
        setGameState,
        gameState,
        messages,
        index,
      }),
    }));
    setMessageComponents(updatedComponents);
    console.log(messageComponents)
  }, [messages]);
  

  const [inputMessage, setInputMessage] = useState('');
  const [nextOptionButtons, setNextOptionButtons] = useState([]);
  const [inputState, setInputState] = useState([]);

  const handleSendMessage = (text = null) => {
    
    let sendText = ((typeof text === "string") && text) ? text : inputMessage.trim()

    if (sendText !== '') {
      const newMessage = {
        id: messages[messages.length],
        text: sendText,
        sender: 'player',
        avatar: 'https://via.placeholder.com/150',
      };
      setMessages([...messages, newMessage]);
      setInputMessage('');

    }
  };

  


//   const streamGameResponse = () => {
//     const simulatedResponse = `<text>Alice: Hi I like cats!</text>
// <image>cat</image>
// <sound>meow</sound>
// <text>Alice: I hate dogs. What animal do you like?
// Bob: I like cats!<text>
// <change abigail_relationship>1</change abigail_relationship>
// <next option>option 1</next option><next option>option 2</next option><next option>option 3</next option><next option>option 4</next option>`;
//     // Function to stream the simulated response
//     const streamResponse = async () => {
//       for (let i = 0; i < simulatedResponse.length; i++) {
//         await new Promise((resolve) => setTimeout(resolve, 100));
//         // ((prevText) => prevText + simulatedResponse[i]);
//       }
//     };
//    }
    
  return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%', // Ensures the box takes up 100% of its parent width
            mx: 'auto', // Centers the box within its parent by automatically adjusting the horizontal margins
        }}
        >
        <ChatScrollBox messages={messageComponents} />
        <MessageInputFieldBox
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
        />
        </Box>
  );
};

export default ChatPage;
